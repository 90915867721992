

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   /* background-image:url("/src/bg_image1.svg"); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1{
  /* position:absolute; */
  top:3rem;
  left:1rem;
  color:#281414;
  /* z-index: 10 ;  */
  margin:0 ; 
  padding:0; 
}
.search{
  /* position: absolute; */
  top: 1rem;
  left: 50%;
  transform: translateX();
  width: 50%;
  /* max-width: 400px; */
  /* z-index: 10; */
}

/* #zoom{
  position: absolute;
  bottom:18vh;
  right:4vw;
  z-index: 20 ; 
} */
#zoom{
  display: flex;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
  margin-bottom:1px;
  user-select:none;
  position:absolute;
  /* z-index:20 ;  */
  bottom:105px;
  right:10px; 
  color: #656565;
  font-weight: bold;
  font-size: 18px;
  background-color: rgb(255, 255, 255);
  width:40px; 
  height:40px; 
}

#info{
  background-color:rgb(26,179,148);
  width:3em; 
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border:none;
  /* font-weight: bold ; */
  color:rgb(255, 255, 255);
  font-size: 1em;
}

.customButton{
  color:rgb(255,255,255);
  appearance:none;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
  border:none;
  font-family:inherit;
  font-weight:600;
  outline:none;
  background-color:rgb(26,179,148);
  border-radius:calc(0.5 * var(--base-unit,8px));
  cursor:pointer;
  font-size:1.1em;
  padding:0.5em 2.786em 0.6em;
  text-align:left;
}

.homepage{
  /* margin-top: 0px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 90vw;
  height: 70vh;
  background-image: url("/src/park.gif");
  background-size: 100% 100%;
  padding: 1em;
  border-radius: 22px;
  color: #0483a3;
}


#homepageHeader{
  display: flex;
  text-align: justify;
  /* flex-direction: row; */
  /* width: 0vw; */
  /* padding:0 5em ;  */
  align-items: center;
  justify-content: space-around;
  /* opacity: 100% !important; */
}

#homepageBody{
  font-size: .80em;
  color:rgb(2, 91, 126);
  text-align: justify;
}
#instructions{
  color:rgb(4, 62, 150);
  font-size: 1em;
}
#logo{
  height: 2.2em;
  width: 2.2em;

}
#homepageFooter{
   display: flex;
  justify-content: center;
}
.wid{
  display: flex;
  /* justify-content: center; */
  /* justify-content: flex-start; */
  
  width: 100vw;
}

.language{
  position: absolute;
  top:0px ; 
  left:0px ; 
  color:rgb(255,255,255);
  appearance:none;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
  border:none;
  font-family:inherit;
  font-weight:600;
  outline:none;
  background-color:rgb(26,179,148);
  border-radius:calc(0.5 * var(--base-unit,8px));
  cursor:pointer;
  font-size:.7em;
  padding:0.5em 2.786em 0.6em;
  text-align:left;
}